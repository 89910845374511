import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { breakpoint } from 'src/utils/mediaQueries';
import { Button } from 'components/basics';
import { FormattedMessage } from 'react-intl';
import FormModal from 'components/pages/Index/FormModals/FormModal';
import { FORM_TYPES } from 'components/pages/Index/FormModals/formConfigs';

const StyledGrid = styled(Grid)`
  min-height: 20rem;
  margin-bottom: 3%;
  ${breakpoint.down('lg')`
   margin-top: 10%`};
`;

const ContentGrid = styled(Grid)`
  width: 100%;
`;

const WebinarProgramList = styled(Typography)`
  & li ul li {
    font-size: 1.5rem !important;
  }
  margin-top: ${({ isChecklist }) => (isChecklist ? '2em' : '1em')};
  ${breakpoint.down('lg')`
  font-size: 1.5em;
  `}
  & ol li {
    font-size: 1.5em;
    padding: 0.5em 0;
  }
`;

const WebinarProgramBlock = ({
  selectedWebinar,
  lang,
  isChecklist,
  isWhitepaper,
}) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <StyledGrid container justify={'center'} alignItems={'flex-start'}>
      <ContentGrid
        container
        item
        xs={10}
        sm={8}
        md={6}
        lg={5}
        alignItems="center"
        justify="center"
      >
        <Typography variant="h5" style={{ textAlign: 'center' }}>
          {isChecklist || isWhitepaper ? (
            selectedWebinar?.frontmatter?.program
          ) : (
            <FormattedMessage id="webinars.program" />
          )}
        </Typography>
        <br />
        <WebinarProgramList isChecklist={isChecklist || isWhitepaper}>
          <div
            dangerouslySetInnerHTML={{
              __html: selectedWebinar?.html.replace(
                /\s(?=[\?!]*(\?|!))/g,
                '&nbsp;'
              ),
            }}
          />
        </WebinarProgramList>
        <Button
          size="large"
          variant="contained"
          color="primary"
          mt={2}
          onClick={() => setOpenModal(!openModal)}
        >
          <FormattedMessage
            id={
              isChecklist
                ? 'button.downloadChecklist'
                : isWhitepaper
                ? 'button.discoverWhitepaper'
                : 'button.watchWebinar'
            }
          />
        </Button>
        <FormModal
          lang={lang}
          open={openModal}
          handleClose={() => setOpenModal(!openModal)}
          type={
            FORM_TYPES[
              `${
                isChecklist
                  ? 'CHECKLIST'
                  : isWhitepaper
                  ? 'WHITEPAPER'
                  : 'WEBINAR'
              }_${lang.toUpperCase()}`
            ]
          }
          webinar={selectedWebinar?.frontmatter?.id}
        />
      </ContentGrid>
    </StyledGrid>
  );
};

export default WebinarProgramBlock;
