import React, { useState } from 'react';
import Layout from 'components/layout';
import IntroBlocks from 'components/pages/Webinars/IntroBlocks';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import WebinarTitleBlock from 'components/pages/Webinars/WebinarTitleBlock';
import { find } from 'lodash';
import WebinarProgramBlock from 'src/components/pages/Webinars/WebinarProgramBlock';
import LinkBlocks from 'components/LinkBlock/LinkBlocks';
import LinkBlockBackground from 'components/pages/Solution/Marketing/LinkBlockBackground';
import { breakpoint } from 'utils/mediaQueries';
import ResourcesBlocks from 'components/pages/Webinars/ResourcesBlocks';

const LinkGrid = styled(Grid)`
  margin-top: -4rem;
  position: relative;
  padding-top: 10rem;
  padding-bottom: 4rem;
  background-position: top;
  ${breakpoint.down('lg')`
  padding-top: 6rem;padding-bottom: 2rem;margin-top: 0;`}
  ${breakpoint.down('md')`
  padding-bottom: 4rem;`}
  ${breakpoint.down('sm')`padding-top:0;padding-bottom: 6rem;`};
`;

const WebinarsPage = props => {
  const [selectValue, setSelectValue] = useState('');
  const {
    dataIntroBlockTitle,
    allImages,
    allWebinars: { nodes: webinars },
    dataBlockLinkBody,
  } = props.data;

  const linkBlocksData = {
    markdownContent: dataBlockLinkBody,
  };
  const selectedWebinar =
    find(webinars, node => node.frontmatter.id === selectValue) || webinars[0];
  const images = [
    find(allImages.nodes, image => image.base === 'manGreen.png'),
    find(allImages.nodes, image => image.base === 'girlBlue.png'),
  ];
  return (
    <Layout location={props.location} seo={{ ...props.seo }}>
      <IntroBlocks
        data={{ dataIntroBlockTitle, images }}
        selectValue={selectValue}
        setSelectValue={setSelectValue}
        webinars={webinars}
        selectedWebinar={selectedWebinar}
        isChecklist={props.checklist}
        isWhitepaper={props.whitepaper}
      />
      <WebinarTitleBlock
        selectedWebinar={selectedWebinar}
        lang={props.pageContext.langKey}
        isChecklist={props.checklist}
        isWhitepaper={props.whitepaper}
      />
      <WebinarProgramBlock
        selectedWebinar={selectedWebinar}
        lang={props.pageContext.langKey}
        isChecklist={props.checklist}
        isWhitepaper={props.whitepaper}
      />
      <ResourcesBlocks lang={props.pageContext.langKey} />
      <LinkGrid>
        <LinkBlocks
          data={linkBlocksData.markdownContent.nodes}
          lang={props.pageContext.langKey}
        />
        <LinkBlockBackground />
      </LinkGrid>
    </Layout>
  );
};

export default WebinarsPage;
