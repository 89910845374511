import React from 'react';
import styled from 'styled-components';
import {
  Grid,
  Typography,
  Select,
  NativeSelect,
  MenuItem
} from '@material-ui/core';
import { breakpoint } from 'utils/mediaQueries';
import ImagesBlocks from 'components/pages/ClientCase/ProblematicBlocks/ImagesBlocks';
import { colors } from 'styles';
import { FormattedMessage } from 'react-intl';

const StyledGrid = styled(Grid)`
  height: 15rem;
  padding-top: 3%;
  ${breakpoint.down('lg')`
   margin-top: 7%`};
`;

const ContentGrid = styled(Grid)`
  width: 100%;
  text-align: center;
  padding-top: 2rem;
`;

const StyledMenuItem = styled(MenuItem)`
  font-family: ${props => `${props.theme.typography.fontFamily}`};
  font-size: ${props => `${props.theme.typography.subtitle2.fontSize}px`};
  &:focus {
    background-color: transparent !important;
    color: white;
  }
  &:hover {
    background-color: ${colors.shades.orange.light} !important;
    color: white;
  }
`;

const StyledSelect = styled(Select)`
  text-overflow: ellipsis !important;
  overflow: hidden;
  width: 33em;
  ${breakpoint.down('sm')`
   width: 20em;`};
  display: flex;
  flex-direction: row;
  justify-content: center;
  outline: none;
  background-color: rgba(173, 147, 160, 0.1);
  border-radius: 1rem;
  > div {
    display: flex;
    flex-direction: row;
    text-overflow: ellipsis !important;
  }
  > div > div {
    text-overflow: ellipsis !important;
    width: 100%;
    ${breakpoint.down('sm')`
    width: 75%; text-overflow: ellipsis;`};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1rem;
    font-family: ${props => `${props.theme.typography.fontFamily}`};
    font-size: ${props => `${props.theme.typography.subtitle2.fontSize}px`};
    &:focus {
      background-color: transparent;
    }
  }
  > div > svg {
    padding-left: 0.5em;
    color: ${colors.orange};
    width: 3rem;
    height: 3rem;
    top: -0.4rem;
    right: 0;
    position: absolute;
  }
  &:before {
    content: none;
  }
  &:after {
    content: none;
  }
`;

const StyledMobileSelect = styled(NativeSelect)`
  text-overflow: ellipsis !important;
  width: 33em;
  ${breakpoint.down('sm')`
   width: 20em;`};
  display: flex;
  flex-direction: row;
  justify-content: center;
  outline: none;
  background-color: rgba(173, 147, 160, 0.1);
  border-radius: 1rem;
  > div {
    display: flex;
    flex-direction: row;
    text-overflow: ellipsis !important;
  }
  > div > div {
    text-overflow: ellipsis !important;
    width: 100%;
    ${breakpoint.down('sm')`
    width: 75%; text-overflow: ellipsis;`};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1rem;
    font-family: ${props => `${props.theme.typography.fontFamily}`};
    font-size: ${props => `${props.theme.typography.subtitle2.fontSize}px`};
    &:focus {
      background-color: transparent;
    }
  }
  > div > svg {
    padding-left: 0.5em;
    color: ${colors.orange};
    width: 3rem;
    height: 3rem;
    top: -0.4rem;
    right: 0;
    position: absolute;
  }
  &:before {
    content: none;
  }
  &:after {
    content: none;
  }
`;

const SelectorBlock = ({
  setSelectValue,
  webinars,
  data,
  selectedWebinar,
  isChecklist,
  isWhitepaper,
}) => {
  const isMobile =
    typeof window !== 'undefined' &&
    /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
  return (
    <StyledGrid container justify={'center'} alignItems={'flex-start'}>
      <ContentGrid
        container
        item
        xs={10}
        sm={8}
        md={6}
        lg={5}
        alignItems="center"
        justify="center"
      >
        {isMobile ? (
          <StyledMobileSelect
            value={selectedWebinar?.frontmatter.id}
            onChange={e => setSelectValue(e.target.value)}
            displayEmpty
          >
            {webinars.map(web => (
              <option value={web.frontmatter.id}>
                {web.frontmatter.title}
              </option>
            ))}
          </StyledMobileSelect>
        ) : (
          <StyledSelect
            value={selectedWebinar?.frontmatter.id}
            onChange={e => setSelectValue(e.target.value)}
            displayEmpty
            autoFocusItem={false}
          >
            <StyledMenuItem value="" disabled>
              <Typography>
                <b>
                  <FormattedMessage
                    id={
                      isChecklist
                        ? 'checklists.listOfChecklists'
                        : isWhitepaper
                        ? 'whitepapers.listOfWhitepapers'
                        : 'webinars.listOfWebinars'
                    }
                  />
                </b>
              </Typography>
            </StyledMenuItem>
            {webinars.map(web => (
              <StyledMenuItem disableScrollLock value={web.frontmatter.id}>
                <Typography>{web.frontmatter.title}</Typography>
              </StyledMenuItem>
            ))}
          </StyledSelect>
        )}
      </ContentGrid>
      <ImagesBlocks data={data} />
    </StyledGrid>
  );
};

export default SelectorBlock;
