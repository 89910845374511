import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Typography, Grid } from '@material-ui/core';
import { Container, Content } from 'components/basics';
import { colors } from 'styles';
import { breakpoint } from 'utils/mediaQueries';
import SubIntroBackground from 'components/pages/Solution/Marketing/SubIntroBackground';
import SelectorBlock from 'src/components/pages/Webinars/SelectorBlock';

const GridIntro = styled(Grid)`
  ${Content};
  max-width: 1080px;
  padding: 0 2rem;
  ${breakpoint.down('lg')`
  padding:1rem 2rem;`};
`;
const StyledContainer = styled(Container)`
  padding-top: 2rem;
  ${breakpoint.down('md')`
  padding-top: 0; 
  `}
`;

const Title = styled(Typography)`
  & span {
    color: ${colors.purple};
  }
  width: 75%;
  ${breakpoint.down('lg')`
  font-size: 3em; width: 100%;`};
`;

const IntroBlocks = ({ data, setSelectValue, webinars, selectedWebinar, isChecklist, isWhitepaper }) => {
  const [width, setWidth] = useState(200);
  const [heightHeader, setHeightHeader] = useState(0);
  const [heightIntro, setHeightIntro] = useState(0);
  const [heightTitleIntro, setHeightTitleIntro] = useState(0);

  const updateDimensions = () => {
    const headerNav = document.getElementById('headerNav');
    const heightIntroDim = document.getElementById('introBlock');
    const heightTitleIntroDim = document.getElementById('titleIntroBlock');
    setWidth(window.innerWidth);
    setHeightHeader(headerNav && headerNav.offsetHeight);
    setHeightIntro(heightIntroDim && heightIntroDim.offsetHeight);
    setHeightTitleIntro(
      heightTitleIntroDim && heightTitleIntroDim.offsetHeight
    );
  };

  useEffect(() => {
    const headerNav = document.getElementById('headerNav');
    if (headerNav) {
      updateDimensions();
    }
  }, [heightHeader]);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const heightBackground = heightHeader + heightIntro + 216;
  const heightTitle = heightTitleIntro + heightHeader + 96;
  return (
    <StyledContainer id="introBlock">
      <GridIntro container spacing={24} sm={2} id="titleIntroBlock">
        <Title
          dangerouslySetInnerHTML={{ __html: data.dataIntroBlockTitle.html }}
          variant="h1"
        />
      </GridIntro>
      <SelectorBlock
        setSelectValue={setSelectValue}
        webinars={webinars}
        data={data}
        selectedWebinar={selectedWebinar}
        isChecklist={isChecklist}
        isWhitepaper={isWhitepaper}
      />
      <SubIntroBackground
        widthWindow={width}
        heightBackground={heightBackground}
        heightTitle={heightTitle}
      />
    </StyledContainer>
  );
};

export default IntroBlocks;
