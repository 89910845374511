import React, { useState } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import styled from 'styled-components';
import { breakpoint } from 'src/utils/mediaQueries';
import { FormattedMessage } from 'react-intl';
import FormModal from 'components/pages/Index/FormModals/FormModal';
import { FORM_TYPES } from 'components/pages/Index/FormModals/formConfigs';

const StyledGrid = styled(Grid)`
  height: 17.5rem;
  ${breakpoint.down('sm')`
   margin-top: -20%`};
`;

const ContentGrid = styled(Grid)`
  width: 100%;
  text-align: center;
`;

const StyledTypography = styled(Typography)`
  font-size: 2em !important;
`;

const StyledButton = styled(Button)`
  margin-top: 3.5em;
  ${breakpoint.down('lg')`
   margin-top: 1em`};
`;

const WebinarTitleBlock = ({
  selectedWebinar,
  lang,
  isChecklist,
  isWhitepaper,
}) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <StyledGrid container justify={'center'} alignItems={'flex-start'}>
      <ContentGrid
        container
        item
        xs={10}
        sm={8}
        md={6}
        lg={5}
        alignItems="center"
        justify="center"
      >
        {selectedWebinar && selectedWebinar.frontmatter && (
          <div>
            <StyledTypography variant="h4">
              {selectedWebinar.frontmatter.subtitle ||
                `"${selectedWebinar.frontmatter.title}"`}
            </StyledTypography>
            {selectedWebinar.frontmatter.duration && (
              <Typography>
                <FormattedMessage id="webinars.duration" /> :{' '}
                {selectedWebinar.frontmatter.duration}
              </Typography>
            )}
            <StyledButton
              size="large"
              variant="contained"
              color="primary"
              mt={2}
              onClick={() => setOpenModal(!openModal)}
            >
              <FormattedMessage
                id={
                  isChecklist
                    ? 'button.downloadChecklist'
                    : isWhitepaper
                    ? 'button.discoverWhitepaper'
                    : 'button.watchWebinar'
                }
              />
            </StyledButton>
            <FormModal
              lang={lang}
              open={openModal}
              handleClose={() => setOpenModal(!openModal)}
              type={
                FORM_TYPES[
                  `${
                    isChecklist
                      ? 'CHECKLIST'
                      : isWhitepaper
                      ? 'WHITEPAPER'
                      : 'WEBINAR'
                  }_${lang.toUpperCase()}`
                ]
              }
              webinar={selectedWebinar.frontmatter.id}
            />
          </div>
        )}
      </ContentGrid>
    </StyledGrid>
  );
};

export default WebinarTitleBlock;
